import React from 'react';
import logo from './../assets/logo1.png';
import { AppBar, Tabs, Tab, Button, Grid, Toolbar, useTheme, useMediaQuery, Typography, Box, IconButton, Badge, TextField, InputAdornment } from '@mui/material'
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import AssignmentIndOutlinedIcon from '@mui/icons-material/AssignmentIndOutlined';
import ShoppingBagOutlinedIcon from '@mui/icons-material/ShoppingBagOutlined';
import MiddleDrawer from './MiddleDrawer';
import NavBar from "./navBar";
import { Routes, Route, Link, useLocation, Navigate } from 'react-router-dom';
import SearchIcon from '@mui/icons-material/Search';

export default function Header() {
    const theme = useTheme();

    const [value, setValue] = React.useState('/');
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const smallScreen = useMediaQuery(theme.breakpoints.down('md'));

    const handleSignInClick = () => {
        console.log('Sign in clicked');
    };

    const bigContent = (
        <>
            <Grid container  >

                <Grid item xs={3} mt={1} pt={2} style={{ textAlign: 'center' }}>

                    <img
                        src={logo}
                        alt={logo}
                        style={{ width: '190px', height: '55px' }}

                    />

                </Grid>
                <Grid item xs={7} mt={4} style={{ textAlign: 'left' }}>
                    <NavBar />

                </Grid>
                <Grid item xs={2} style={{ textAlign: 'right' }}>
                    <Box
                        mt={2}
                        sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}
                    >
                        <Link to={'/signIn'} style={{ textDecoration: 'none', color: 'inherit' }}>
                            <Box sx={{ display: 'flex', alignItems: 'center', mr: 1, cursor: 'pointer' }} >
                                <AssignmentIndOutlinedIcon color="primary" sx={{ fontSize: 25 }} />
                                <Typography variant="subtitle2" sx={{ ml: 1 }}>signIn</Typography>
                            </Box>
                        </Link>

                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Badge badgeContent={4} color="primary">
                                <FavoriteBorderIcon color="primary" sx={{ fontSize: 25 }} />
                            </Badge>
                            <Typography variant="subtitle2" sx={{ ml: 1 }}>favorite</Typography>
                        </Box>
                        <Link to={'/cart'} style={{ textDecoration: 'none', color: 'inherit' }}>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <Badge badgeContent={4} color="primary">
                                    <ShoppingBagOutlinedIcon color="primary" sx={{ fontSize: 25 }} />
                                </Badge>
                                <Typography variant="subtitle2" sx={{ ml: 1, textDecoration: 'none' }}>Cart</Typography>
                            </Box>
                        </Link>
                    </Box>
                </Grid>
            </Grid>

        </>




    );

    const smallContent = (
        <Grid container  >


            <Grid item xs={8} mt={3} pr={6} style={{ textAlign: 'right' }}>
                <Link to='/'>
                    <img
                        src={logo}
                        alt={logo}
                        style={{ width: '170px', height: '35px' }}

                    />
                </Link>

            </Grid>
            <Grid item xs={4} style={{ textAlign: 'right' }}>
                <Box
                    mt={2}
                    sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}
                >
                    <Link to={'/signIn'} style={{ textDecoration: 'none', color: 'inherit' }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', mr: 1, cursor: 'pointer' }} >
                            <AssignmentIndOutlinedIcon color="primary" sx={{ fontSize: 25 }} />
                        </Box>
                    </Link>

                    <Link to={'/cart'} style={{ textDecoration: 'none', color: 'inherit' }}>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Badge badgeContent={4} color="primary">
                                <ShoppingBagOutlinedIcon color="primary" sx={{ fontSize: 25 }} />
                            </Badge>
                        </Box>
                    </Link>
                </Box>
            </Grid>


        </Grid>


    )


    return (
        <AppBar position="fixed" color="inherit" sx={{ zIndex: theme.zIndex.drawer + 1 }}>
            <Toolbar sx={{ flexDirection: 'column', width: '100%' }}>
                {smallScreen ? smallContent : bigContent}
                <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                    <TextField
                        placeholder="Search..."
                        size="small"
                        fullWidth
                        variant="standard"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon color="primary" />
                                </InputAdornment>
                            ),
                            disableUnderline: true,  // Removes the underline

                        }}
                        sx={{
                            width: '100%',
                            borderRadius: 1,            // Optional: Slightly rounded corners
                        }}
                    />
                </Box>
            </Toolbar>
        </AppBar>
    );
}


